
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { Login } from "@/api/api";

const username = ref("");
const password = ref("");
const loginErr = ref("");

const onUserInput = (e: any) => {
  username.value = e.target.value;
};
const onPasswordInput = (e: any) => {
  password.value = e.target.value;
};

export default defineComponent({
  name: "LoginView",
  components: {},
  setup() {
    const router = useRouter();

    const goBack = () => {
      router.back();
    };

    const submit = async (e: any) => {
      e.preventDefault();
      const data = await Login(username.value, password.value);

      loginErr.value = data.message;

      if (data.message === "Successfully logged in.") {
        localStorage.setItem("token", data.data.AccessToken);
        localStorage.setItem("refresh_token", data.data.RefreshToken);
        localStorage.setItem("idToken", data.data.IdToken);
        router.push("/dashboard");
      }
    };

    return {
      username,
      password,
      onUserInput,
      onPasswordInput,
      submit,
      goBack,
      loginErr,
    };
  },
});
