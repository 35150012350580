
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import "./css/root.css";

export default defineComponent({
  name: "App",
  components: {
  },
  setup() {
    const currentRoute = computed(() => useRoute().name);
    return { currentRoute };
  },
});
