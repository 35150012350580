
import { defineComponent, ref } from "vue";
import { ResetUserPasswordConfirm } from "@/api/api";
import { useRoute } from "vue-router";

const username = ref("");
const code = ref("");
const password = ref("");
const cpassword = ref("");
const Err = ref("");

const onPasswordInput = (e: any) => {
  password.value = e.target.value;
};

const onPasswordConfirmInput = () => {
  if (cpassword.value !== password.value) {
    Err.value = "Passwords do not match";
  } else {
    Err.value = "";
  }
};

const reset = async () => {
  const res = await ResetUserPasswordConfirm(username.value, password.value, code.value);

  if (res.status === 200) {
    window.location.href = "/login";
  } else {
    Err.value = "Something went wrong";
  }
};

export default defineComponent({
  name: "PasswordReset",
  setup() {
    const route = useRoute();
    // Get the username from the URL using param username and vue router
    username.value = route.query.username ? route.query.username?.toString() : "";
    code.value = route.query.code ? route.query.code?.toString() : "";

    return {
      username,
      code,
      password,
      cpassword,
      Err,
      onPasswordInput,
      onPasswordConfirmInput,
      reset,
    };
  },
});
