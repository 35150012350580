
import { defineComponent } from "vue";
import { cartOutline, homeOutline } from "ionicons/icons";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Sidebar",
  props: {
    isDashboard: {
      type: Boolean,
    },
  },
  setup() {
    const router = useRouter();

    const goTo = (path: string) => {
      router.push(path);
    };
    return {
      cartOutline,
      homeOutline,
      goTo,
    };
  },
});
