
import { defineComponent, ref } from "vue";

const buttonActive = ref(false);

export default defineComponent({
  setup() {
    return {
      buttonActive,
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isSubActive: {
      type: Boolean,
      required: true,
    },
    renewal_date: {
      type: String,
      required: true,
    },
    cancel_at_end: {
      type: Boolean,
      required: true,
    },
    subscription_id: {
      type: String,
      required: true,
    },
  },
  methods: {
    openModal() {
      this.$emit("modalOpen", this.subscription_id, this.renewal_date);
    },
  },
  emits: ["modalOpen"],
});
