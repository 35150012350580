
import { defineComponent, ref } from "vue";
import { CancelSubscription, GetSubscriptions } from "@/api/api";
import { subscription } from "@/types/subscription";
import SubscriptionCard from "../components/SubscriptionCard.vue";

const purchasable = ref(false);
const buttonActive1Month = ref(true);
const buttonActive3Month = ref(true);
const buttonActiveCancel = ref(true);
const isSubscriptions = ref(false);
const renewal_date = ref(new Date("1970-01-01").toLocaleDateString());
const modalOpen = ref(false);

const subscriptions = ref([] as subscription[]);
const chosenSub = ref("");
const chosenSubDate = ref("");

const openModal = (s: string, d: string) => {
  modalOpen.value = true;
  chosenSub.value = s;
  chosenSubDate.value = d;
};

const getSubs = async () => {
  isSubscriptions.value = false;
  subscriptions.value = [];
  const res = await GetSubscriptions();
  if (res.message === "Successfully retrieved user subscriptions.") {
    subscriptions.value = res.data.subscriptions;
    isSubscriptions.value = res.data.subscriptions.length > 0;

    let activeSub = false;
    res.data?.subscriptions.forEach((sub: subscription) => {
      const cancelDate = new Date(sub.Cancel_At);
      if (cancelDate.getTime() > Date.now()) {
        activeSub = true;
      }
    });

    if (activeSub) {
      purchasable.value = false;
    } else {
      purchasable.value = true;
    }
  }
};

const tryCancelSubscription = async () => {
  buttonActiveCancel.value = false;
  const response = await CancelSubscription(chosenSub.value);
  if (response.message === "Processing user subscription cancellation.") {
    buttonActiveCancel.value = true;
    modalOpen.value = false;
    getSubs();
  } else {
    buttonActiveCancel.value = true;
    console.log(response);
  }
};

const requestCheckout = async (price_id: string) => {
  const formData = new FormData();
  formData.append("price_id", price_id);

  console.log(price_id);

  const response = await fetch("https://api.solarus.club/api/v1/payment", {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: formData, // body data type must match "Content-Type" header
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")!}`,
    },
  });
  const data = await response.json();
  return data;
};

export default defineComponent({
  components: {
    SubscriptionCard,
  },
  setup() {
    getSubs();

    const buy1Month = async () => {
      buttonActive1Month.value = false;
      // const price_id = "price_1MGt9aLoaC1bKhLIOs08bP3R";
      const price_id = "price_1Mf9DbLoaC1bKhLIRLedmgCe";

      const res = await requestCheckout(price_id);

      if (res.status === 200) {
        buttonActive1Month.value = true;
        console.log(res.data.session_url);
        window.location.href = res.data.session_url;
      }
    };

    const buy3Month = async () => {
      buttonActive3Month.value = false;
      // const price_id = "price_1MIotBLoaC1bKhLIvqkLP2YL";
      const price_id = "price_1Mf9E8LoaC1bKhLIRw24rREj";

      const res = await requestCheckout(price_id);

      if (res.status === 200) {
        buttonActive3Month.value = true;
        console.log(res.data.session_url);
        window.location.href = res.data.session_url;
      }
    };

    const gotoBilling = () => {
      window.location.href = "https://billing.stripe.com/p/login/aEU9Ez7jR2fdaty9AA";
    };

    return {
      buy1Month,
      buy3Month,
      buttonActive1Month,
      buttonActive3Month,
      isSubscriptions,
      renewal_date,
      modalOpen,
      openModal,
      tryCancelSubscription,
      buttonActiveCancel,
      subscriptions,
      chosenSub,
      chosenSubDate,
      purchasable,
      gotoBilling,
    };
  },
  methods: {},
});
