
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { RefreshLogin, GetSubscriptions, GetUser, LinkDiscord } from "@/api/api";
import { subscription } from "@/types/subscription";
import Sidebar from "../components/Sidebar.vue";
import Dashboard from "../components/Dashboard.vue";
import PurchaseView from "./PurchaseView.vue";

const loading = ref(true);
const isDark = ref(false);
const name = ref("John Doe");
const email = ref("");
const username = ref("");

// Discord details
const discord_avatarid = ref("");
const discord_id = ref("");
const discord_linked = ref(false);
const discord_username = ref("");

// Onetap details
const onetap_username = ref("");
const solarus_uid = ref(0);
const onetap_uid = ref(0);

const created_at = ref("");
const subscription_value = ref("Not subscribed");

// view selection
const isDashboard = ref(true);

const toggleTheme = () => {
  isDark.value = !isDark.value;
};

const grabInfo = async () => {
  const data = await GetUser();
  const subs = await GetSubscriptions();

  if (subs.message === "Successfully retrieved user subscriptions.") {
    let activeSub = false;

    console.log(subs.data);
    subs.data?.subscriptions.forEach((sub: subscription) => {
      const cancelDate = new Date(sub.Cancel_At);
      if (cancelDate.getTime() > Date.now()) {
        activeSub = true;
      }
    });

    subscription_value.value = subs.data?.subscriptions.length > 0 && activeSub ? "Subscribed" : "Not subscribed";
  }

  if (data.message === "Successfully retrieved user details.") {
    loading.value = false;
    name.value = data.data.data_username;
    discord_avatarid.value = data.data.data_avatarID;
    discord_id.value = data.data.data_discordID;
    onetap_username.value = data.data.data_onetapUsername;
    email.value = data.data.data_email;
    solarus_uid.value = data.data.data_uid;
    username.value = data.data.data_username;
    created_at.value = data.data.data_created
      ? new Date(data.data.data_created).toLocaleDateString()
      : "N/A";
    onetap_uid.value = data.data.data_onetapUID;
    discord_username.value = data.data.data_discordUsername;

    localStorage.setItem("username", data.data.data_username);
  }

  if (
    data.message === "Token is expired." ||
    data.message === "Invalid access token." ||
    data.message === "Invalid or expired token."
  ) {
    loading.value = true;
    const refresh = await RefreshLogin();
    localStorage.setItem("token", refresh.data.AccessToken);
    localStorage.setItem("idToken", refresh.data.IdToken);
    grabInfo();
  }

  if (discord_avatarid.value !== "null" && discord_id.value !== "") {
    discord_linked.value = true;
  }

  console.log(discord_avatarid.value);
  console.log(discord_id.value);
};

export default defineComponent({
  components: {
    Sidebar,
    Dashboard,
    PurchaseView,
  },
  setup() {
    const router = useRouter();

    isDashboard.value = router.currentRoute.value.path === "/dashboard";

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("code")) {
      LinkDiscord(urlParams.get("code")!).then((data) => {
        if (data.message === "Successfully linked your account.") {
          localStorage.setItem("discordAccessToken", data.data.access_token);
          localStorage.setItem("discordRefreshToken", data.data.refresh_token);
          localStorage.setItem("discordExpiry", data.data.expires_in);
          grabInfo();
        }
      });
    }

    const logout = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("discordAccessToken");
      localStorage.removeItem("discordRefreshToken");
      localStorage.removeItem("discordExpiry");
      localStorage.removeItem("idToken");
      router.replace("/");
    };
    grabInfo();
    return {
      isDark,
      toggleTheme,
      name,
      logout,
      discord_avatarid,
      discord_id,
      discord_linked,
      onetap_username,
      email,
      solarus_uid,
      created_at,
      username,
      subscription_value,
      loading,
      isDashboard,
      onetap_uid,
      discord_username,
    };
  },
  methods: {
    handlePurchaseButton(e: boolean) {
      isDashboard.value = e;
    },
  },
});
