import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import SignupView from "../views/SignupView.vue";
import VerifyView from "../views/VerifyView.vue";
import DashboardView from "../views/DashboardView.vue";
import PurchaseView from "../views/PurchaseView.vue";
import Dashboard from "../components/Dashboard.vue";
import SendPasswordReset from "../views/SendPasswordReset.vue";
import PasswordReset from "../views/PasswordReset.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/signup",
    name: "signup",
    component: SignupView,
  },
  {
    path: "/verify",
    name: "verify",
    component: VerifyView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    children: [
      {
        path: "",
        name: "home",
        component: Dashboard,
      },
      {
        path: "purchase",
        name: "purchase",
        component: PurchaseView,
      },
    ],
  },
  {
    path: "/reset",
    name: "reset",
    component: SendPasswordReset,
  },
  {
    path: "/reset/confirm",
    name: "resetconfirm",
    component: PasswordReset,
  },
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.addRoute({
  path: "/",
  name: "home",
  component: HomeView,
});

export default router;
