
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

// Error states
const passwordError = ref(false);
const passwordConfirmError = ref(false);
const emailError = ref(false);
const userError = ref(false);
const onetapUserError = ref(false);
const onetapUIDError = ref(false);

// Error states
const globalError_Content = ref("");
const passwordError_Content = ref(
  "",
);
const passwordConfirmError_Content = ref("");
const emailError_Content = ref("");
const usernameError_Content = ref("");
const onetapUserError_Content = ref("");
const onetapUIDError_Content = ref("");

// Value input states
const password = ref("");
const passwordConfirm = ref("");
const username = ref("");
const email = ref("");
const onetap_username = ref("");
const onetap_uid = ref("");

// regex
const emailreg = /^\S+@\S+\.\S+$/;
const userreg = /^[a-zA-Z0-9]*[-]?[a-zA-Z0-9]*$/;
const passreg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/;
const onetapUIDregex = /^\s*-?[0-9]{1,10}\s*$/;

const updatePassword = (e: any) => {
  password.value = e.target.value;

  // Update password error state
  passwordError.value = !passreg.test(password.value);
  passwordError_Content.value = passwordError.value ? "Your password must contain at least 1 of each of the following as well as be 8 characters minimum in length: uppercase, lowercase, special, and number character" : "";
};

const updatePasswordConfirm = (e: any) => {
  passwordConfirm.value = e.target.value;

  // Update password confirm error state
  passwordConfirmError.value = passwordConfirm.value !== password.value;
  passwordConfirmError_Content.value = passwordConfirmError.value ? "Your passwords do not match!" : "";
};

const updateEmail = (e: any) => {
  email.value = e.target.value;

  // Update email error state
  emailError.value = !emailreg.test(email.value);
  emailError_Content.value = emailError.value ? "Please enter a valid email." : "";
};

const updateNormalUsername = (e: any) => {
  username.value = e.target.value;

  // Update username error state
  userError.value = !userreg.test(username.value);
  usernameError_Content.value = userError.value ? "Please enter a valid username." : "";
};

const updateOTUsername = (e: any) => {
  onetap_username.value = e.target.value;

  // Update OT uid error state
  onetapUserError.value = !userreg.test(onetap_username.value);
  onetapUserError_Content.value = onetapUserError.value ? "Please enter a valid onetap username." : "";
};

const updateUID = (e: any) => {
  onetap_uid.value = e.target.value;

  // Update OT uid error state
  onetapUIDError.value = !onetapUIDregex.test(onetap_uid.value);
  onetapUIDError_Content.value = onetapUIDError.value ? "Please enter a valid onetap uid." : "";
};

export default defineComponent({
  name: "SignupView",
  setup() {
    const router = useRouter();

    const goBack = () => {
      router.back();
    };

    // Signup request
    const signup = async (formdata: any) => {
      const response = await fetch("https://api.solarus.club/api/v1/signup", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: formdata, // body data type must match "Content-Type" header
      });
      const data = await response.json();
      return data;
    };

    const submit = async (e: any) => {
      e.preventDefault();
      if (!passwordError.value && !emailError.value && !userError.value && !onetapUserError.value && !onetapUIDError.value) {
        const formData = new FormData();
        formData.append("username", username.value);
        formData.append("password", password.value);
        formData.append("email", email.value);
        formData.append("ot_username", onetap_username.value);
        formData.append("ot_uid", onetap_uid.value);

        const data = await signup(formData);

        if (data.status === 200) {
          router.push(`/verify?email=${email.value}`);
        } else {
          // Scroll to top to error from API
          window.scrollTo(0, 0);
          globalError_Content.value = data.message;
          // Show error from API
        }
      }
    };
    return {
      // Values
      userError,
      password,
      passwordConfirm,
      username,
      email,
      onetap_username,
      onetap_uid,
      // Setters
      updatePassword,
      updatePasswordConfirm,
      updateEmail,
      updateOTUsername,
      updateUID,
      updateNormalUsername,
      // Error content
      passwordError_Content,
      passwordConfirmError_Content,
      emailError_Content,
      usernameError_Content,
      onetapUserError_Content,
      onetapUIDError_Content,
      globalError_Content,
      submit,
      goBack,
    };
  },
});
