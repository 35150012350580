
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { ResetUserPassword } from "@/api/api";

const emailreg = /^\S+@\S+\.\S+$/;
const userreg = /^[a-zA-Z0-9]*[-]?[a-zA-Z0-9]*$/;

const status = ref("");
const error = ref("");
const errState = ref(true);
const username = ref("");

const onUserInput = (e: any) => {
  username.value = e.target.value;

  if (!emailreg.test(username.value) && !userreg.test(username.value)) {
    error.value = "Invalid username/email";
    errState.value = true;
  } else {
    error.value = "";
    errState.value = false;
  }

  console.log(errState.value);
};

const submit = () => {
  if (!errState.value) {
    ResetUserPassword(username.value)
      .then((res) => {
        status.value = res.message;
      })
      .catch((err) => {
        status.value = err.response.data;
      });
  }
};

export default defineComponent({
  name: "SendPasswordReset",
  setup() {
    const router = useRouter();

    const goBack = () => {
      router.back();
    };
    return {
      submit,
      status,
      goBack,
      username,
      onUserInput,
      error,
    };
  },
});
