
import { defineComponent, ref } from "vue";
import { GetUser } from "../api/api";

const isLoggedIn = ref(false);
const username = ref("");

const checkLogin = async () => {
  if (localStorage.getItem("token")) {
    // Make request
    const user = await GetUser();

    if (user.message === "Successfully retrieved user details.") {
      isLoggedIn.value = true;
      username.value = user.data.data_username;
    }
  }
};

export default defineComponent({
  name: "HomeView",
  components: {},
  setup() {
    checkLogin();
    return { isLoggedIn, username };
  },
});
