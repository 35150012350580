import { createApp } from "vue";
// @ts-ignore
import { IonicVue } from "@ionic/vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

const app = createApp(App);
app.use(IonicVue);
app.use(router);

// treat all tags starting with 'ion-' as custom elements
app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith("ion-");

app.mount("#app");
