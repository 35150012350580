export const GetUser = async () => {
  const response = await fetch("https://api.solarus.club/api/v1/user", {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")!}`,
    },
  });
  const data = await response.json();
  return data;
};

export const ResetUserPassword = async (user: string) => {
  const formdata = new FormData();
  formdata.append("username", user);

  const response = await fetch("https://api.solarus.club/api/v1/reset", {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: formdata, // body data type must match "Content-Type" header
  });
  const data = await response.json();
  return data;
};

export const Login = async (username: string, password: string) => {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);

  const response = await fetch("https://api.solarus.club/api/v1/login", {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: formData, // body data type must match "Content-Type" header
  });
  const data = await response.json();
  return data;
};

export const RefreshLogin = async () => {
  const formData = new FormData();
  formData.append("username", localStorage.getItem("username")!);
  formData.append("password", "");
  formData.append("refresh_token", localStorage.getItem("refresh_token")!);

  const response = await fetch("https://api.solarus.club/api/v1/login", {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: formData, // body data type must match "Content-Type" header
  });
  const data = await response.json();
  return data;
};

export const GetSubscriptions = async () => {
  const response = await fetch("https://api.solarus.club/api/v1/subscriptions", {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")!}`,
    },
  });
  const data = await response.json();
  return data;
};

export const CancelSubscription = async (sub_id: string) => {
  const formData = new FormData();
  formData.append("sub_id", sub_id);
  const response = await fetch("https://api.solarus.club/api/v1/subscription", {
    method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")!}`,
    },
    body: formData,
  });
  const data = await response.json();
  return data;
};

export const LinkDiscord = async (code: string) => {
  const formData = new FormData();
  formData.append("code", code);

  const response = await fetch("https://api.solarus.club/api/v1/link", {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")!}`,
    },
    body: formData, // body data type must match "Content-Type" header
  });

  const data = await response.json();
  return data;
};

export const ResetUserPasswordConfirm = async (user: string, password: string, code: string) => {
  const formdata = new FormData();
  formdata.append("username", user);
  formdata.append("password", password);
  formdata.append("code", code);

  const response = await fetch("https://api.solarus.club/api/v1/reset/confirm", {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: formdata, // body data type must match "Content-Type" header
  });
  const data = await response.json();
  return data;
};
