
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

let router;

export default defineComponent({
  emits: ["handlePurchaseButton"],
  setup() {
    router = useRouter();
    return {};
  },
  methods: {
    navigate() {
      router.push("/dashboard/purchase");
      this.$emit("handlePurchaseButton", false);
    },
  },
  props: {
    loading: Boolean,
    solarus_uid: Number,
    username: String,
    email: String,
    discord_id: String,
    discord_avatarid: String,
    discord_linked: Boolean,
    onetap_username: String,
    created_at: String,
    subscription_value: String,
    onetap_uid: Number,
    discord_username: String,
  },
});
